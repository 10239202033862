.dashboard-user-info,
.dashboard-total-balance,
.dashboard-top-market,
.dashboard-recent-transactions {
  border-radius: 0.625rem;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
}

.light-color-font {
  color: #aaaaaa;
}

.top-market-table {
  display: block;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.top-market-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.top-market-table::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
}

.top-market-table::-webkit-scrollbar-thumb:hover {
  background: #001131;
}

.top-market-table::-webkit-scrollbar {
  display: block;
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
  width: 0.31rem;
}

.dashboard-market-table-head {
  background: #f7f4f4;
  border-radius: 0.5rem;
}

.dashboard-recent-trans-table th {
  opacity: 0.5;
}

.dashboard-recent-trans-table td {
  color: #1b224a;
}

.dashboard-recent-trans-dropdown>button {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 160%;
  text-align: right;
  color: #1b224a;
}