.coin-dropdown-btn {
  border: 1px solid #00feb9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  border-radius: 0.75rem;
}

.coin-dropdown-btn:hover {
  border: 1px solid #00feb9;
}

.coin-dropdown-btn::after {
  display: none;
}

.coin-wallet-info {
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 1.25rem;
}

.coin-network-info {
  padding-right: 7.5rem;
}

.coin-title {
  color: #172a4f;
  opacity: 0.69;
}

.alert {
  display: none;
}

.deposit-problem,
.deposit-faq {
  padding-left: 2.5rem;
  padding-right: 14.3rem;
}

.deposit-problem-content,
.deposit-faq-item,
.deposit-learn-more-btn {
  color: #172a4f;
  opacity: 0.69;
}

.deposit-faq-item {
  text-align: left;
}

.wallet-address {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.drop-down {
  max-height: 18.75rem;
  overflow-y: scroll;
}

.drop-down::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.drop-down::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
}

.drop-down::-webkit-scrollbar-thumb:hover {
  background: #001131;
}

.drop-down::-webkit-scrollbar {
  display: block;
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
  width: 0.31rem;
}

.try-wallet-btn {
  background: linear-gradient(270deg, #00feb9 0%, #00fafd 100%);
  border: none;
  padding: 0.62rem 1.25rem;
  margin-left: auto;
  font-weight: 600;
  border-radius: 0.31rem;
  color: #172a4f !important;
}

.submit-btn {
  margin: auto;
  border-radius: 0.31rem;
  padding: 0.62rem 3.75rem;
  color: #172a4f !important;
}

.submit-btn.disabled {
  background: #ccc;
  color: gray;
}

.deposit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.deposit-modal-overlay.show {
  opacity: 1;
  pointer-events: auto;
}

.deposit-modal-content {
  padding: 1.25rem;
  border-radius: 0.31rem;
  max-width: 31.25rem;
  min-height: 12.5rem;
  width: 100%;
  text-align: center;
  animation: modalFadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    flex-grow: 1;
    font-size: 0.87rem;
    max-width: 70%;
    margin: 0 auto 1.25rem;
    padding-top: 1.25rem;
  }
  .wallet-adapter-dropdown {
    flex-grow: 1;
    margin: 0 auto;
  }
}
.asset-input-container {
  align-items: center;
  gap: 0.62rem;
  background: #172a4f;
  padding: 0.25rem;
  border-radius: 0.5rem;
  margin-bottom: 1.75rem;

  .content {
    display: flex;
  }

  .balance {
    display: flex;
    justify-content: space-between;

    .loader {
      border: 1px solid #fff;
      border-top: 2px solid transparent;
    }
  }
}

.asset-input-container input {
  flex: 1;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
  padding: 0,5rem;
  outline: none;
}

.asset-input-container button {
  background: #00feb9;
  color: #172A4F !important;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  align-self: center;
  font-size: 0.75rem;
  font-weight: 600;
}

.asset-input-container button.disabled {
  background: #ccc;
  pointer-events: none;
}

.asset-input-container select {
  background: #172a4f;
  border: none;
  color: #fff;
  padding: 0.5rem 0 0.5rem 0.5rem;
  outline: none;
}

.balance, .usd-value {
  color: #fff;
  font-size: 0.87rem;
  display: flex;
  gap: 2px;
  align-items: center;
}

.balance.wrapper {
  border-bottom: 0.2px solid #ccc;
  padding-bottom: 0.5rem;
}

.rate-row {
  display: flex;
  justify-content: space-between;
  padding-top: 0.37rem;

  div {
    font-size: 0.87rem;
    color: #fff;
  }
}

@keyframes modalFadeIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@media (max-width: 1024px) {
  .deposit-problem,
  .deposit-faq {
    padding-right: 1.75rem;
    padding-left: 0.6rem;
  }
}

@media (max-width: 767px) {
  .coin-network-info {
    padding-right: 0;
  }
}

@media (max-width: 578px) {
  .wallet-address-info img {
    width: 12.5rem;
  }

  .coin-wallet-info {
    box-shadow: none;
    border-radius: 0;
  }
}
