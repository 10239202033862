.form_title {
  font-size: calc(16px + 1.6vw);
  color: #172A4F;
  font-weight: 700;
  padding-bottom: 3vh;
  display: inline-block;
}

.label {
  color: #4E4E4E;
  display: inline-block;
  font-size: calc(0.6rem + .4vw);
}

input {
  border-radius: 2.5rem;
  border: 1px solid #172A4F;
  padding: 0 1.5vw;
}

.submit_btn {
  width: 100%;
  background-color: #00FEB9;
  color: #fff;
  text-align: center;
  font-weight: 600;
  border: none;
  border-radius: 1.56rem;
  margin-top: 2vh;
  height: 3.5rem;
  cursor: pointer;
}

.form {
  width: 40%;
}

#email {
  margin-bottom: 3vh;
  width: 100%;
  height: 3.12rem;
}

.checkbox {
  color: #4E4E4E;
  line-height: 1.25rem;
  padding: 1.25rem 0;
}

.side_btns_wrap {
  display: flex;
  align-items: center;
  gap: 0.62rem;
}

.splitter {
  font-size: 1.25rem;
}

.bg_wrap {
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  top: 33%;
  left: 60%;
  height: 28vw;
}

.bg_small {
  position: absolute;
  left: 50%;
  top: 20%;
}

@media screen and (max-width: 1024px) {
  .form {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .form {
    width: 80%;
  }

  .bg_wrap {
    display: none;
  }

  #email {
    height: 2.5rem;
  }

  .submit_btn {
    height: 2.5rem;
  }
}

@media screen and (max-width: 425px) {
  .form {
    width: 100%;
  }
}